// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-album-src-templates-catalog-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/catalog.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-catalog-js" */),
  "component---node-modules-gatsby-theme-album-src-templates-group-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/group.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-group-js" */),
  "component---node-modules-gatsby-theme-album-src-templates-home-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/home.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-home-js" */),
  "component---node-modules-gatsby-theme-album-src-templates-name-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/name.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-name-js" */),
  "component---node-modules-gatsby-theme-album-src-templates-page-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-page-js" */),
  "component---node-modules-gatsby-theme-album-src-templates-version-js": () => import("./../../../node_modules/gatsby-theme-album/src/templates/version.js" /* webpackChunkName: "component---node-modules-gatsby-theme-album-src-templates-version-js" */)
}

